.highlighted-container {
  padding: 10px; /* Adjust padding as needed */
  padding-right: 10px;
  /* border: 1px solid #ccc; Border for the container */
  /* background-color: rgba(128, 128, 128, 0.2); Transparent grey background */
  transition: background-color 0.3s; /* Smooth transition effect */
}

.highlighted-container:hover {
  background-color: rgba(
    128,
    128,
    128,
    0.5
  ); /* Darker transparent grey background on hover */
}
